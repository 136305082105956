const keys = {
  enter: 13,
  space: 32,
  end: 35,
  home: 36,
  left: 37,
  up: 38,
  right: 39,
  down: 40
};

class Tabs {
  constructor (element) {
    this.container = $(element);
    this.list = this.container.find('.tab-list');
    this.buttons = this.container.find('.tab-button:not(.tab-button--standalone)');
    this.buttons_standalone = this.container.find('.tab-button.tab-button--standalone');
    this.panes = this.container.find('.tab-pane');
    this.breakpoint = '(max-width: 767px)';
    this.matchMedia = window.matchMedia(this.breakpoint);
    this.mountAsCollapse = this.matchMedia.matches;
    this.defaultTab = Number(this.container.attr('data-tabs-default'));

    if (!Number.isInteger(this.defaultTab) || this.defaultTab >= this.buttons.length) {
      this.defaultTab = undefined;
    }

    this.initListener();
    this.init();
  }

  initListener () {
    this.matchMedia.addListener(e => {
      this.mountAsCollapse = e.matches;
      this.init();
    });

    window.addEventListener('load', this.triggerHash.bind(this));
    window.addEventListener('hashchange', this.triggerHash.bind(this));

    this.buttons_standalone.on('click', (e) => {
      this.toggle(e.currentTarget);
    });

    this.buttons.on('click', (e) => {
      this.toggle(e.currentTarget);
    });

    this.buttons.on('keydown', (e) => {
      switch (e.keyCode) {
        case keys.right:
          e.preventDefault();
          this.onArrowPress('right', e.currentTarget);
          break;
        case keys.left:
          e.preventDefault();
          this.onArrowPress('left', e.currentTarget);
          break;
        case keys.up:
          e.preventDefault();
          this.onArrowPress('left', e.currentTarget);
          break;
        case keys.down:
          e.preventDefault();
          this.onArrowPress('right', e.currentTarget);
          break;
        case keys.home:
          e.preventDefault();
          this.focusButton(0);
          break;
        case keys.end:
          e.preventDefault();
          this.focusButton(this.buttons.length - 1);
          break;
        case keys.space:
          e.preventDefault();
          if (!this.mountAsCollapse) {
            this.toggle(e.currentTarget);
          }
          break;
        case keys.enter:
          e.preventDefault();
          if (!this.mountAsCollapse) {
            this.toggle(e.currentTarget);
          }
          break;
        default:
          break;
      }
    });
  }

  triggerHash () {
    const hash = window.location.hash;
    if (hash) {
      const targetContained = this.panes.find(hash);
      let targetButton;

      if (targetContained.length) {
        const paneId = targetContained.closest('.tab-pane').attr('id');
        targetButton = this.buttons.filter(`[data-owns="${paneId}"]`);
      }

      if (targetButton && targetButton.length) {
        targetButton.trigger('click');
      }
    }
  }

  /**
   * Init either collapse or tab menu
   * based on matching breakpoint
   */
  init () {
    if (this.mountAsCollapse) {
      this.initCollapse();
      this.panes.attr('role', 'region');
    } else {
      this.initTabs();
      this.panes.attr('role', 'tabPanel');
    }
  }

  /**
   * Init collapsible menu with
   * special aria attributes and
   * and close all active panes
   */
  initCollapse () {
    this.container.css('paddingBottom', '');
    this.buttons.removeAttr('tabindex');
    this.list.attr('data-orientation', 'vertical');
    this.panes.attr('hidden', true);
    this.close(this.buttons, this.panes);
  }

  /**
   * Init tab menu with aria attributes
   * and a custom tabindex navigation
   */
  initTabs () {
    this.panes.attr('hidden', true);
    this.list.attr('data-orientation', 'horizontal');
    this.buttons.attr('tabindex', -1);
    this.close(this.buttons, this.panes);

    if (this.defaultTab === undefined) {
      this.toggle(this.buttons.first());
    } else {
      this.toggle(this.buttons[this.defaultTab]);
    }
  }

  /**
   * Toggles passed button and associated pane.
   * Has different behaviour while in collapsible mode.
   *
   * @param {Node} buttonNode
   */
  toggle (buttonNode) {
    const button = $(buttonNode);
    const pane = $(`#${button.attr('data-owns')}`);
    if (pane.length > 0 && button.parent().hasClass('active') && this.mountAsCollapse) {
      this.close(button, pane);
    } else if (pane.length > 0) {
      if (!this.mountAsCollapse) {
        this.close(this.buttons.not(button), this.panes.not(pane));
      }
      this.open(button, pane);
    }
  }

  /**
   * Works with single jQuery nodes and jQuery nodelists.
   *
   * @param {jQueryNode} button
   * @param {jQueryNode} pane
   */
  open (button, pane) {
    button.parent().addClass('active');
    if (button.attr('aria-selected') !== undefined) {
      button.attr('aria-selected', true);
    }
    if (button.attr('aria-expanded') !== undefined) {
      button.attr('aria-expanded', true);
    }
    pane.addClass('active');
    pane.removeAttr('hidden');

    if (!this.mountAsCollapse) {
      button.removeAttr('tabindex');
    }
  }

  /**
   * Works with single jQuery nodes and jQuery nodelists.
   *
   * @param {jQueryNode} button
   * @param {jQueryNode} pane
   */
  close (button, pane) {
    button.parent().removeClass('active');
    if (button.attr('aria-selected') !== undefined) {
      button.attr('aria-selected', false);
    }
    if (button.attr('aria-expanded') !== undefined) {
      button.attr('aria-expanded', false);
    }
    pane.removeClass('active');
    pane.attr('hidden', true);

    if (!this.mountAsCollapse) {
      button.attr('tabindex', -1);
    }
  }

  /**
   * Focus specific button
   * @param {int} index
   */
  focusButton (index) {
    const selectedButton = this.buttons.get(index);
    if (selectedButton && index !== null) {
      selectedButton.focus();
    }
  }

  /**
   * Handle arrow key navigation through
   * tabs, based on aria-orientation
   *
   * @param {String} direction (up,down,right,left)
   * @param {Node} buttonNode
   */
  onArrowPress (direction, buttonNode) {
    const button = $(buttonNode);
    const orientation = this.list.attr('data-orientation');
    const buttonIndex = this.buttons.index(button);
    let index = null;
    if ((orientation === 'horizontal' && direction === 'right') || (orientation === 'vertical' && direction === 'right')) {
      index = buttonIndex + 1;
      if (index > (this.buttons.length - 1)) {
        index = 0;
      }
    }

    if ((orientation === 'horizontal' && direction === 'left') || (orientation === 'vertical' && direction === 'left')) {
      index = buttonIndex - 1;
      if (index < 0) {
        index = (this.buttons.length - 1);
      }
    }
    if (this.mountAsCollapse) {
      // Circle through the second half of buttons (only visible below breakpoint)
      index = (index % (this.buttons.length)) + this.buttons.length;
    } else {
      // Circle through the first half of buttons (only visible above breakpoint)
      index = index % (this.buttons.length);
    }

    this.focusButton(index);
  }
}

export { Tabs };
export default Tabs;
