/* global CMS */
import 'vite/modulepreload-polyfill';

import './bootstrap';
import './tabs';
import './ownselect';
import './modules/focusWithoutScrolling';
import './scroll-page-event';
import './swiper';
import 'bootstrap-accessibility-plugin';
import './slick.js';
import './fancybox.js';
import './scrollbar.js';

import NavButton from "../../components/10-atoms/nav-button/nav-button.js";
import NavPanel from "../../components/20-molecules/nav-panel/nav-panel.js";
import LazyVideo from "../../components/20-molecules/lazy-video/lazy-video.js";
import LazyVimeo from "../../components/20-molecules/lazy-vimeo/lazy-vimeo.js";
import BackgroundVideo from "../../components/40-modules/background-video/background-video.js";
import GeneralVideo from '../../components/20-molecules/general-video/general-video.js';
import Modal from "../../components/30-organisms/modal/modal.js";
import Tabs from "../../components/40-modules/tabs/tabs.js";
import {Slider} from "./modules/slider.js";
import {config as listSliderConfig} from "../../components/30-organisms/list-slider/list-slider.js";
import {config as carouselConfig} from "../../components/30-organisms/carousel/carousel.js";
import {Flipster} from "./modules/flipster.js";
import {initAnisMap} from "./modules/anis-map.js"
import {
  config as coverFlowCarouselConfig
} from "../../components/30-organisms/coverflow-carousel/coverflow-carousel.js";
import ContrastMode from "../../components/20-molecules/nav-panel/contrast-mode.js";
import FontSettings from "../../components/20-molecules/nav-panel/font-settings.js";
import {HideAfterScroll, SmoothScroll} from "../../components/20-molecules/scrollindicator/scrollindicator.js";
import {Banner} from "../../components/40-modules/banner/banner.js";
import Gallery from "./modules/js-gallery-navigation.js";
import {Zoomable} from "./modules/zoomable.js";
import {initAccessibilitySlider} from "./modules/accessibility_slider.js";
import {initGoogleMaps} from "./modules/locations.js";
import {initDynamicLayers} from './modules/dynamic-layers.js';
import {initDynamicLayersB} from "./modules/dynamic-layers-variant-b.js";
import {initFlipCards} from './modules/flipcards.js';
import Navigation from "../../components/40-modules/navigation/navigation.js";
import LangSwitcher from "../../components/20-molecules/lang-switcher/lang-switcher.js";

import Loader from "./loader.js";
import U010AudioPlayer from "./components/u010-audio-player.js";
import C001Accordion from "./components/001-accordion.js";
import C042ShortsSlider from "./components/042-shorts-slider.js";
import C025Quicklinks from "./components/025-quicklinks.js";
import U015Hotspots from "./components/u015-hotspots.js";
import C044StorySlider from "./components/044-story-slider.js";
import {
  U016SingleSocialMediaPostFacebook,
  U016SingleSocialMediaPostInstagram,
  U016SingleSocialMediaPostX,
  U016SingleSocialMediaPostLinkedin
} from "./components/u016-single-social-media-post.js";
import CookieBanner from "./components/cookiebanner.js";
import setupYoutubeAndGoogleMapsContent from "./modules/privacy.js";

document.documentElement.classList.add((('ontouchstart' in document.documentElement) ? 'touch' : 'no-touch'));

/**
 * Components
 */

function init(refresh = false) {
  if(!refresh) {
    const loader = new Loader();

    loader.register('U010AudioPlayer', U010AudioPlayer);
    loader.register('C001Accordion', C001Accordion);
    loader.register('C042ShortsSlider', C042ShortsSlider);
    loader.register('U015Hotspots', U015Hotspots);
    loader.register('U016SingleSocialMediaPostFacebook', U016SingleSocialMediaPostFacebook);
    loader.register('U016SingleSocialMediaPostInstagram', U016SingleSocialMediaPostInstagram);
    loader.register('U016SingleSocialMediaPostX', U016SingleSocialMediaPostX);
    loader.register('U016SingleSocialMediaPostLinkedin', U016SingleSocialMediaPostLinkedin);
    loader.register('C025Quicklinks', C025Quicklinks);
    loader.register('C044StorySlider', C044StorySlider);
    loader.register('CookieBanner', CookieBanner);

    loader.init(window.document);

    document.addEventListener('DOMContentLoaded', function () {
      loader.ready();
    });

    window.addEventListener("resize", function () {
      loader.windowResize();
    });

    if (window.CMS) {
      CMS.$(window).on('cms-content-refresh', function () {
        loader.cmsRefresh();
      });
    }
  }

  setupYoutubeAndGoogleMapsContent();

  //
  // Background Video
  //
  const videoContainer = $('[data-video-container]');
  if (videoContainer.length > 0) {
    videoContainer.each((index, element) => new BackgroundVideo(element, {}));
  }

  //
  // YouTube Lazyload/embed
  //
  const youtubeLazyElements = $('.youtube-lazy');

  if (youtubeLazyElements.length > 0) {
    youtubeLazyElements.each((index, element) => new LazyVideo(element));
  }

  //
  // General Video
  //
  const generalVideoElements = $('.general-video');

  if (generalVideoElements.length > 0) {
    generalVideoElements.each((index, element) => new GeneralVideo(element));
  }

  //
  // Vimeo Lazyload/embed
  //
  const vimeoLazyElements = $('.vimeo-lazy');

  if (vimeoLazyElements.length > 0) {
    vimeoLazyElements.each((index, element) => new LazyVimeo(element));
  }

  //
  // Modal Script
  //
  const modalContainers = $('.modal');

  if (modalContainers.length > 0) {
    modalContainers.each((index, element) => new Modal(element));
  }

  //
  // Tabs
  //
  const tabsContainers = $('[data-tabs]');

  if (tabsContainers.length > 0) {
    tabsContainers.each((index, element) => new Tabs(element));
  }

  //
  // Default Slider (not bootstraps carousel)
  //
  const sliderContainer = $('[data-slider]');

  if (sliderContainer.length > 0) {
    sliderContainer.each((index, element) => Slider(element, listSliderConfig));
  }
  //
  // Carousel
  //
  const carouselContainer = $('[data-carousel]');

  if (carouselContainer.length > 0) {
    carouselContainer.each((index, element) => Slider(element, carouselConfig));
  }

  //
  // Coverflow Slider
  //
  const coverflowSliderContainer = $('[data-coverflow]');

  if (coverflowSliderContainer.length > 0) {
    coverflowSliderContainer.each((index, element) => Flipster(element, coverFlowCarouselConfig));
  }

  // const accessibilityNavigation = $('[data-accessnav]')
  // if (accessibilityNavigation.length > 0) {
  //   import(/* webpackChunkName: "linguatech" */'~js/vendor/readpremium15')
  // }

  /**
   * Navigation Parts
   */
  new Navigation({
    navigationSelector: '[data-navigation]',
    navigationContentSelector: '[data-navigation-content]',
    tutorialLayerSelector: '[data-layer-tutorial]',
    defaultLayerSelector: '[data-layer-default]'
  });

  NavButton({ buttonSelector: '[data-nav-button]', handleAttr: 'data-nav-button' })

  $('.lang-switcher, .nav-lang-switcher').each((index, node) => {
    return new LangSwitcher(node);
  });

  $('[data-nav-panel]').each((index, node) => {
    const element = $(node);

    return new NavPanel(element, element.attr('data-nav-panel'));
  });

  ContrastMode($('[data-contrast]'));
  FontSettings($('[data-font]'));

  //
  // Smoothscroll
  //
  const scrollindicator = $('.scrollindicator');

  if (scrollindicator.length > 0) {
    SmoothScroll();
    HideAfterScroll(scrollindicator);
  }

  //
  // Small Banner
  //
  const banner = $('.banner');

  if (banner.length > 0) {
    Banner();
  }
  //
  // Gallery JS
  //
  const galleryElements = $('.gallery-wrap');

  if (galleryElements.length > 0) {
    galleryElements.each((index, element) => new Gallery(element));
  }
  //
  // Zoomable
  //
  const zoomableElement = $('.js-zoomable-link');
  if (zoomableElement.length > 0) {
    Zoomable();
  }
  //
  // Slider
  //
  const aSliderElements = document.querySelectorAll('.teaser-slider-container');
  if (aSliderElements.length > 0) {
    aSliderElements.forEach((element) => {
      initAccessibilitySlider(element);
    });
  }

  //
  // Locations
  //
  const locationElements = $('.js-aldryn-locations');
  if (locationElements.length > 0) {
    initGoogleMaps();
  }

  //
  // ReadSpeaker Extension, close focus element by space and enter
  //
  const readSpeakerButton = $('.rsbtn');

  if (readSpeakerButton.length > 0) {
    $('.rsbtn').on('keydown', '.rsbtn_player_item', function (event) {
      if (event.which === 32 || event.which === 13) {
        event.preventDefault();
        $(this).click();
      }
    });
  }
  //
  // Quicklink Extension
  //
  const squareQuickModule = $('.quick-link-wrapper.small');

  if (squareQuickModule.length > 0) {
    $('.quick-link-wrapper.small').each(function () {
      // Cache the highest
      var highestBox = 0;

      // Select and loop the elements you want to equalise
      $('.quick-link-element', this).each(function () {
        // If this box is higher than the cached highest then store it
        if ($(this).height() > highestBox) {
          highestBox = $(this).height();
        }
      });
      // Set the height of all those children to whichever was highest
      $('.quick-link-element', this).height(highestBox);
    });
    $(window).resize(function () {
      $('.quick-link-wrapper.small').each(function () {
        $('.quick-link-element', this).removeAttr('style');
        // Cache the highest
        var newHighestBox = 0;

        // Select and loop the elements you want to equalise
        $('.quick-link-element', this).each(function () {
          // If this box is higher than the cached highest then store it
          if ($(this).height() > newHighestBox) {
            newHighestBox = $(this).height();
          }
        });
        // Set the height of all those children to whichever was highest
        $('.quick-link-element', this).height(newHighestBox);
      });
    });
  }

  const anismaps = document.querySelectorAll('[data-anismap]')

  if (anismaps.length > 0) {
    anismaps.forEach((element) => {
      initAnisMap(element);
    });
  }

  const printButtons = document.querySelectorAll('[data-print-button]');

  if (printButtons.length > 0) {
    printButtons.forEach((element) => {
      element.addEventListener('click', (e) => {
        e.preventDefault();
        window.print();
      });
    });
  }

  initDynamicLayers();
  initDynamicLayersB();
  initFlipCards();
}

init();

try {
  CMS.$(window).on('cms-content-refresh', () => { init(true); });
} catch (e) {
}
